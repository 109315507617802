import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader/Loader';
import ScrollToTop from 'react-scroll-up'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import './App.css';

const AppBar = lazy(() => import('./components/AppBar/AppBar'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Home = lazy(() => import('./pages/Home/Home'));
const MyNFTs = lazy(() => import('./pages/MyNFTs/MyNFTs'));

const App = () => {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<Loader />}>
          <AppBar />
          <ScrollToTop showUnder={200} style={{position: 'fixed', bottom: '40px'}}>
            <FontAwesomeIcon icon={faAngleDoubleUp}/>
          </ScrollToTop>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/myNFTs' element={<MyNFTs />} />
          </Routes>
          <Footer />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
